<template>
  <div class="temoigagnes">
    <div class="temoigagnes-decorator-box">
      <h2 class="temoigagnes-title">
        {{ $t('testimonies.title') }}
      </h2>
    </div>
    <carousel-3d v-if="feedbacks.length" :controls-visible="true">
      <slide v-for="(feedback, index) in feedbacks" :key="index" :index="index">
        <div
          class="slide-item"
          :color="getColor(index)"
        >
          <div class="slide-text">{{ feedback.message }}</div>
          <div class="slide-signature">{{ feedback.username }}</div>
        </div>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
export default {
  name: 'TemoignagesSlider',
  components: {
    Carousel3d,
    Slide
  },
  data: () => ({
    colors: ['pink', 'blue', 'green', 'yellow', 'purple']
  }),
  computed: {
    feedbacks() {
      return this.$store.getters['feedback/feedbacks']
    }
  },
  mounted() {
    this.loading = true
    return this.$store.dispatch('feedback/list')
      .catch(err => this.$err(err))
      .finally(() => (this.loading = false))
  },
  methods: {
    getColor(index) {
      return this.colors[index % this.colors.length]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

/* Overwritten Carousel 3d style */
.carousel-3d-container {
  overflow: visible !important;
  cursor: grab !important;
}

/* Overwritten Carousel 3d style*/
.carousel-3d-slide {
  border: none !important;
  background: transparent !important;
  overflow: visible!important;
}

/* Overwritten Carousel 3d style*/
::v-deep .prev,
::v-deep .next {
  color: transparent !important;
  width: 15px !important;
  height: 30px !important;
  top: 40px;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
  @include media-breakpoint-up(md) {
    width: 60px !important;
    height: 100px !important;
    top: 0;
  }
}

::v-deep .prev {
  background: url("~@/assets/left-arrow-white-mobile.svg") no-repeat !important;
  left: 15px;
  &:hover {
    opacity: 1;
  }
  @include media-breakpoint-up(md) {
    background: url("~@/assets/slider-prev-yellow.svg") no-repeat !important;
    left: calc(50vw - 290px) !important;
    &:hover {
      opacity: .7;
    }
  }
  @include media-breakpoint-up(lg) {
    left: calc(50vw - 540px) !important;
  }
}

::v-deep .next {
  background: url("~@/assets/right-arrow-white-mobile.svg") no-repeat !important;
  background-position-x: right;
  right: 15px;
  &:hover {
    opacity: 1;
  }
  @include media-breakpoint-up(md) {
    background: url("~@/assets/slider-next-yellow.svg") no-repeat !important;
    right: calc(50vw - 290px) !important;
    &:hover {
      opacity: .7;
    }
  }
  @include media-breakpoint-up(lg) {
    right: calc(50vw - 540px) !important;
  }
}

.temoigagnes .carousel-3d-slide:not(.current) {
  @include media-breakpoint-down(lg) {
    display:none;
  }
}

.temoigagnes {
  margin: 100px auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(lg) {
    margin: 135px auto;
  }
}

.temoigagnes-decorator-box {
    position: absolute;
    min-height: 450px;
    @include media-breakpoint-up(lg) {
      margin: 0 auto;
      width: 52%;
      border: 10px var(--green-color) solid;
      min-height: 400px;
    }
}

.temoigagnes-title {
    font-weight: 900;
    font-size: 40px;
    margin: 20px 0;
    @include media-breakpoint-up(lg) {
      background-color: white;
      display: inline-block;
      position: absolute;
      top: -50px;
      left: calc(50% - 170px);
      padding: 0 50px;
    }
}

.slide-item {
  border-radius: 20px;
  border: none;
  padding: 50px;
  font-size: 12px;
  box-shadow: 0px 1px 7px #00000080;
  color: white;
  font-style: italic;
  position: relative;
}

.slide-item:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 20%;
  margin-left: -50px;
  width: 0;
  height: 0;
  border-top: solid 50px #ff0000;
  border-left: solid 0px transparent;
  border-right: solid 50px transparent;
}

.slide-item .slide-text {
  font-weight: 500;
  text-align: left;
}

.slide-item .slide-signature {
  font-weight: 700;
  margin-top: 30px;
  text-align: right;
}

.slide-item[color="green"] {
  background-color: var(--green-color);
}
.slide-item[color="green"]:after {
  border-top-color: var(--green-color);
}

.slide-item[color="yellow"] {
  background-color: var(--yellow-color);
}
.slide-item[color="yellow"]:after {
  border-top-color: var(--yellow-color);
}

.slide-item[color="purple"] {
  background-color: #ba8dc0;
}
.slide-item[color="purple"]:after {
  border-top-color: #ba8dc0;
}

.slide-item[color="blue"] {
  background-color: #64bddf;
}
.slide-item[color="blue"]:after {
  border-top-color: #64bddf;
}

.slide-item[color="pink"] {
  background-color: #efa0a3;
}
.slide-item[color="pink"]:after {
  border-top-color: #efa0a3;
}
</style>
