<template>
  <div id="comment-ca-marche">
    <h2>{{ $t('howItWorks.title') }}</h2>

    <!-- mobile -->
    <div class="d-md-none">
      <agile
        :center-mode="true"
        :speed="1000"
        :dots="false"
      >
        <div class="slide">
          <div class="ccm-item">
            <div class="ccm-circle">
              <img src="@/assets/poi-icon.png">
            </div>
            <div class="ccm-content">
              <div class="ccm-number">
                1.
              </div>
              <div class="ccm-text">
                {{ $t('howItWorks.step1') }}
              </div>
            </div>
          </div>
        </div>
        <div class="slide">
          <div class="ccm-item">
            <div class="ccm-circle">
              <img src="@/assets/shopping-icon.png">
            </div>
            <div class="ccm-content">
              <div class="ccm-number">
                2.
              </div>
              <div class="ccm-text">
                {{ $t('howItWorks.step2') }}
              </div>
            </div>
          </div>
        </div>
        <div class="slide">
          <div class="ccm-item col col-lg-4">
            <div class="ccm-circle">
              <img src="@/assets/delivery-icon.png">
            </div>
            <div class="ccm-content">
              <div class="ccm-number">
                3.
              </div>
              <div class="ccm-text">
                {{ $t('howItWorks.step3') }}
              </div>
            </div>
          </div>
        </div>
        <template slot="prevButton">
          <div class="prev">
            <div class="arrow" />
          </div>
        </template>
        <template slot="nextButton">
          <div class="next">
            <div class="arrow" />
          </div>
        </template>
      </agile>
    </div>

    <!-- desktop -->
    <div class="ccm-items row d-none d-md-flex">
      <div class="ccm-item col col-lg-4">
        <div class="ccm-circle">
          <img src="@/assets/poi-icon.png">
        </div>
        <div class="ccm-content">
          <div class="ccm-number">
            1.
          </div>
          <div class="ccm-text">
            {{ $t('howItWorks.step1') }}
          </div>
        </div>
      </div>
      <div class="ccm-item col col-lg-4">
        <div class="ccm-circle">
          <img src="@/assets/shopping-icon.png">
        </div>
        <div class="ccm-content">
          <div class="ccm-number">
            2.
          </div>
          <div class="ccm-text">
            {{ $t('howItWorks.step2') }}
          </div>
        </div>
      </div>
      <div class="ccm-item col col-lg-4">
        <div class="ccm-circle">
          <img src="@/assets/delivery-icon.png">
        </div>
        <div class="ccm-content">
          <div class="ccm-number">
            3.
          </div>
          <div class="ccm-text">
            {{ $t('howItWorks.step3') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueAgile } from 'vue-agile'
export default {
  name: 'CommentCaMarche',
  components: {
    agile: VueAgile
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#comment-ca-marche {
  background-color: var(--green-color);
  padding: 40px 0;

  @include media-breakpoint-up(lg) {
    padding: 80px 0;
  }
}
h2 {
  color: white;
  font-size: 30px;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  margin: 0 10px 40px 10px;

  @include media-breakpoint-up(md) {
    font-size: 40px;
    margin: 0 0 40px 0;
  }
}
.ccm-items {
  justify-content: space-between;
  display: flex;
  max-width: 850px;
  margin: auto;
}
.ccm-item {
  min-height: 290px;
}
.ccm-circle {
  background-color: white;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 7px #0000005e;
  margin: 0 auto 20px;

  @include media-breakpoint-up(lg) {
    width: 240px;
    height: 240px;
  }
}
.ccm-circle img{
    max-width: 45%;
    max-height: 45%;
}
.ccm-content {
  color: white;
  .ccm-number {
    font-weight: 900;
    font-size: 30px;
  }
  .ccm-text {
    font-weight: 500;
    font-size: 18px;
    padding: 0 50px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}
.prev, .next {
  color: transparent !important;
  width: 70px !important;
  height: 70px !important;
  background-size: contain !important;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.6)) !important;
  cursor: pointer;
}
.prev {
  background: url("~@/assets/slider-prev-white.svg") no-repeat;
}
.next {
  background: url("~@/assets/slider-next-white.svg") no-repeat;
  background-position-x: right;
}
</style>
