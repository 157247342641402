<template>
  <div id="creation-boutique">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-7 title">
          <div v-marked="$t('b2b.title')" class="content-text"></div>
        </div>
        <div class="col-12 col-md-5">
          <img src="@/assets/youpaq-label-yellow.png">
        </div>
      </div>
      <div class="row">
        <div class="col boutique-button">
          <router-link :to="$siteLink($route, 'b2b')">{{ $t('b2b.btn') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreationBoutique'
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#creation-boutique {
  background: url('~@/assets/creation-boutique-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 620px;
  position: relative;

  @include media-breakpoint-up(md) {
    background-position-y: -50px;
  }

  @include media-breakpoint-up(md) {
    background: url('~@/assets/creation-boutique.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    background-position-x: -465px;
    height: 1000px;
  }

  @include media-breakpoint-up(md) {
    background-position-x: center;
  }

}
img {
  position: absolute;
  width: 135px;
  float: right;
  top: -50px;
  left: 30px;

  @include media-breakpoint-up(md) {
    width: 200px;
  }

  @include media-breakpoint-up(lg) {
    width: 250px;
  }
}
.title {
  text-align: left;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 800;
  margin-top: 300px;
  color: white;
  filter: drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.6));

  @include media-breakpoint-up(md) {
    font-size: 35px;
    margin-top: 400px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 35px;
    filter: none;
  }

  .content-text {
    background-color: var(--gray-color);
  }
}
.boutique-button {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;

  @include media-breakpoint-up(md) {
    margin-top: 50px;
  }

  a {
    background-color: var(--dark-yellow-color);
    color: white;
    text-decoration: none;
    padding: 22px 25px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;
    box-shadow: 1px 1px 4px #0000009c;

    @include media-breakpoint-up(lg) {
      font-size: 20px;
    }
  }
}
</style>
