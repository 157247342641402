<template>
  <div style="height: 200px; padding-top: 50px;">
    {{ $t('home.notFound') }}
  </div>
</template>

<script>

export default {
  name: 'ViewNotFound'
}
</script>
