<template>
  <div>
    <div class="d-md-none">
      <SideWidgetMobile />
    </div>
    <div class="d-none d-md-flex">
      <SideWidget :opened="true" />
    </div>
    <HomeHero />
    <div class="d-none d-lg-flex mt-5">
      <Avantages />
    </div>
    <div class="pb-4 pb-md-5">
      <Categories
        :title="$t('home.categories')"
        @select="showSideWidget"
      />
    </div>
    <CommentCaMarche />
    <TemoignagesSlider />
    <CreationBoutique />
  </div>
</template>

<script>
import SideWidgetMobile from '@/components/Layout/SideWidgetMobile.vue'
import SideWidget from '@/components/Layout/SideWidget.vue'
import HomeHero from '@/components/Home/HomeHero.vue'
import Avantages from '@/components/Avantages.vue'
import Categories from '@/components/Categories/Categories.vue'
import CommentCaMarche from '@/components/Home/CommentCaMarche.vue'
import TemoignagesSlider from '@/components/Sliders/TemoignagesSlider.vue'
import CreationBoutique from '@/components/Home/CreationBoutique.vue'

export default {
  name: 'ViewHome',
  components: {
    SideWidgetMobile,
    SideWidget,
    HomeHero,
    Avantages,
    Categories,
    CommentCaMarche,
    TemoignagesSlider,
    CreationBoutique
  },
  methods: {
    showSideWidget(data) {
      this.$store.commit('sidew/focus', { categoryId: data.categoryId })
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';
/* Commment ça marche */
#comment-ca-marche .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  top: calc(70px - 35px);
}
#comment-ca-marche .agile .agile__nav-button--prev {
  left: calc(50% - 155px);
}
#comment-ca-marche .agile .agile__nav-button--next {
  right: calc(50% - 155px);
}
</style>
